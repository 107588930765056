html {
  font-size: 16px;
  font-family: Source Han Sans CN, sans-serif;
}

body,html{
  scroll-behavior: smooth;
}

body, h1, h2, h3, h4, h5, h6, hr, div, p, header, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body:lang(jp) {
  font-family: Helvetica, Tahoma, Arial, Meiryo UI, sans-serif;
}

body, button, input, select, textarea {
  font: 12px Source Han Sans CN, sans-serif;
  &::placeholder {
    font-size: 14px;
    color: #AAA;
    font-weight: 300;
  }
}

[class*=mj-]:focus,a:focus,button:focus,input:focus,textarea:focus {
  outline: 0
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
}

address, cite, dfn, em, var {
  font-style: normal;
}

code, kbd, pre, samp {
  font-family: couriernew, courier, monospace;
}

small {
  font-size: 12px;
}

ul, ol {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

legend {
  color: #000;
}

fieldset, img {
  border: 0;
}

button, input, select, textarea {
  font-size: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.mt-5{
  margin-top: 5px;
}
.mt-10{
  margin-top: 10px;
}
.mt-15{
  margin-top: 15px;
}
.mt-20{
  margin-top: 20px;
}
.ml-10{
  margin-left: 10px;
}
.ml-20{
  margin-left: 20px;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-20{
  margin-bottom: 20px;
}
.mr-20{
  margin-right: 20px;
}
.mr-10{
  margin-right: 10px;
}
.fl{
  float: left;
}
.fr{
  float: right;
}
.tac{
  text-align: center;
}
.tar{
  text-align: right;
}
