.mj_closeButton_container {
  width: 40px;
  height: 40px;
  background: #22211E;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
}

@primary-color: #FFE87B;@animationDelay: 0.6s cubic-bezier(0, 0, 0, 0.8);@maxWidth: 1528px;