.mj_drawer_container {
  width: 500px;
  position: fixed;
  z-index: 99;
  transition: @animationDelay;
  background: white;
  .mj_drawer_closeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 40px;
    height: 40px;
    background: @primary-color;
    left: -44px;
    top: 4px;
    cursor: pointer;
  }
}

.mj_drawer_mask_showNoMask {
  width: 100%;
  min-height: var(--vh);
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  background: rgba(33,37,41,0);
  overflow: auto;
}

.mj_drawer_dark_mask_show {
  width: 100%;
  min-height: var(--vh);
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  animation: darkMaskShow @animationDelay;
  background: rgba(33,37,41,.45);
  overflow: auto;
}
.mj_drawer_light_mask_show {
  width: 100%;
  min-height: var(--vh);
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  animation: lightMaskShow @animationDelay;
  background: rgba(255,255,255,.5);
  overflow: auto;
}

.mj_drawer_dark_mask_close {
  animation: darkMaskClose @animationDelay;
  display: none;
}

.mj_drawer_light_mask_close {
  animation: darkMaskClose @animationDelay;
  display: none;
}

@keyframes darkMaskShow {
  from {
    background: rgba(33,37,41,0);
  }
  to {
    background: rgba(33,37,41,0.45);
  }
}

@keyframes lightMaskShow {
  from {
    background: rgba(255, 255, 255, 0)
  }
  to {
    background: rgba(255,255,255,.5);
  }
}

@keyframes lightMaskClose {
  from {
    background: rgba(255,255,255,.5);
  }
  to {
    background: rgba(255, 255, 255, 0)
  }
}

@keyframes darkMaskClose {
  from {
    background: rgba(33,37,41,0.45);
  }
  to {
    background: rgba(33,37,41,0);
  }
}

.mj_drawer_show_right {
  transform: translateX(0);
}

.mj_drawer_close_right {
  transform: translateX(100%);
}

.mj_drawer_show_top {
  transform: translateY(0);
}

.mj_drawer_close_top {
  transform: translateY(-100%);
}

.mj_drawer_show_bottom {
  transform: translateY(0);
}

.mj_drawer_close_bottom {
  transform: translateY(100%);
}

.mj_drawer_show_left {
  transform: translateX(-100%);
}

.mj_drawer_close_left {
  transform: translateX(0);
}


@primary-color: #FFE87B;@animationDelay: 0.6s cubic-bezier(0, 0, 0, 0.8);@maxWidth: 1528px;