.mj_container {
  min-width: 1200px;
  overflow: auto;
  .mj_container_bgc {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  
  }
  height: 100%;
  padding: 84px 4px 0 4px;
  background-color: #f9f9f9;
  .swiper_container{
    position: relative;
    width: 100%;
    height: calc(var(--vh) - 85px);
    margin: auto;
    user-select: none;
  }
  /* 重要：不设置宽高会出错 */
  .swiper_container .swiper-wrapper{
    width: 100%;
    height: 400px;
    transition-timing-function: cubic-bezier(0, 0, 0, 0.8) !important;
  }
}

@media screen and (max-width: 1200px){
  .swiper_container {
    height: calc(var(--vh) - 84px - var(--scrollbar)) !important;
  }
}

@primary-color: #FFE87B;@animationDelay: 0.6s cubic-bezier(0, 0, 0, 0.8);@maxWidth: 1528px;