.Left {
  position: absolute;
  top: 14px;
  right: -6px;
  display: inline-block;
  width: 28px;
  height: 3.5px;
  border-radius: 4px;
  transition: @animationDelay;
}
.Right {
  position: absolute;
  bottom: 10px;
  right: -6px;
  display: inline-block;
  width: 28px;
  height: 3.5px;
  border-radius: 4px;
  transition: @animationDelay;
}
.toggleArrowsBody {
  display: inline-block;
  width: 15px;
  height: 50px;
  position: relative;
  vertical-align: sub;
  cursor: pointer;
  transition: @animationDelay;
}

@primary-color: #FFE87B;@animationDelay: 0.6s cubic-bezier(0, 0, 0, 0.8);@maxWidth: 1528px;