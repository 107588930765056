.mj_homePage_container {
  width: 100%;
  height: calc(var(--vh) - 84px);
  overflow: hidden;
  display: grid;
  grid-row-gap: 4px;
  grid-template-rows: 1fr 50px;
  transition: @animationDelay;
  .mj_homePage_video_body {
    width: 100%;
    overflow: hidden;
    position: relative;
    .mj_homePage_video {
      width: 100% !important;
      object-fit: cover !important;
    }
  }
  .mj_homePage_video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.mj_homePage_container_hide {
  grid-template-rows: 1fr 0;
}

@primary-color: #FFE87B;@animationDelay: 0.6s cubic-bezier(0, 0, 0, 0.8);@maxWidth: 1528px;