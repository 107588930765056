.mj_position_container{
  overflow: auto;
  &::after {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    background-color: rgba(60,60,60,.6);
    z-index: 2;
  }
  &::before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    background: url("../../../public/images/homepage-poster.png") no-repeat;
    background-size: cover;
    z-index: 2;
  }
  .mj_position_content {
    position: relative;
    padding: 0 66px;
    max-width: 1280px;
    margin: 20px auto;
    background: rgba(255,255,255);
    border-radius: 2px;
    z-index: 3;
  }
  .mj_position_title {
    border-bottom: 1px solid rgba(63,63,63,.2);
    padding: 28px 0;
    margin-bottom: 30px;
    .mj_position_title_text {
      margin-bottom: 6px;
      font-size: 28px;
      font-family: Source Han Sans CN, sans-serif;
      font-weight: bold;
      color: #3F3F3F;
      text-align: center;
    }
    .mj_position_title_decoration {
      width: 120px;
      height: 14px;
      background: #FFE66F;
      color: #FFF;
      margin: 0 auto;
      display: flex;
      padding: 0 5px;
      align-items: center;
      justify-content: center;
      .mj_position_title_decoration_dot {
        width: 2px;
        height: 2px;
        background: #FFF;
        margin: 0 8px;
      }
    }
  }
  .mj_position_content_desc {
    max-width: 1280px;
    background: rgba(255,255,255);
    border-radius: 2px;
    font-size: 18px !important;
    color: #3f3f3f !important;
  }
  .mj_position_desc_title {
    font-size: 18px;
    font-weight: 800;
    margin: 20px 0;
  }
  .mj_position_footer {
    height: 110px;
    margin-top: 42px;
    border-top: 1px solid rgba(63,63,63,.2);
    color: #3F3F3F;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@primary-color: #FFE87B;@animationDelay: 0.6s cubic-bezier(0, 0, 0, 0.8);@maxWidth: 1528px;