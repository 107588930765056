.mj_honor_container {
  height: calc(var(--vh) - 88px);
  background: #f0f2f5;
  overflow: auto;
  padding-left: 4px;
  .mj_container_bgc {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  }
  .mj_honor_list {
    position: relative;
    width: 100%;
    height: calc(var(--vh) - 88px) !important;
    margin: auto;
    //.swiper_container .swiper-wrapper{
    //  width: 100%;
    //  height: 400px;
    //}
    .mj_honor_list_item {
      width: 100%;
      display: flex;
      height: auto;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
      .mj_honor_list_item_detail_decoration {
        height: 9px;
        width: 100%;
        background: #DCDCDC;
      }
      .mj_honor_list_item_detail_container {
        .mj_honor_list_item_detail {
          width: 520px;
          min-height: 80px;
          background: #22211E;
          padding-top: 44px;
          position: relative;
          margin-bottom: 4px;
          &:last-child {
            margin-bottom: 0;
          }


          .mj_honor_list_item_detail_bgcYear{
            font-size: 64px;
            font-family: Visby CF, sans-serif;
            font-weight: 100;
            color: #413E33;
            position: absolute;
            top: 16px;
            height: 47px;
            left: 12px;
            line-height: 47px;
            opacity: 0.4;
          }
          .mj_honor_list_item_detail_text {
            width: 100%;
            font-size: 18px;
            word-break: break-word;

            font-weight: 300;
            color: #FFFFFF;
            bottom: 12px;
            text-align: right;
            right: 23px;
            top: 44px;
            padding: 0 23px 18px 23px;
          }
        }
        .mj_honor_list_item_title {
          width: 520px;
          height: 40px;
          line-height: 40px;
          padding: 0 34px 0 13px;
          display: flex;
          background: #22211E;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 4px;
        }
        .mj_honor_list_item_title_text {
          font-size: 20px;

          font-weight: 500;
          color: #81ADFF;
        }
        .mj_honor_list_item_title_subTitle {
          width: 181px;
          font-size: 10px;
          font-family: Visby CF, sans-serif;
          font-weight: 100;
          color: #3F3D32;
          display: flex;
          justify-content: space-between;
          text-align-last: justify;
        }
      }

      .mj_honor_list_item_year {
        display: flex;
        height: 40px;
        line-height: 40px;
        padding: 0 14px;
        background: #22211E;
        width: 160px;
        justify-content: space-between;
        .mj_honor_list_item_yearTitle {
          font-size: 14px;
          font-family: Visby CF, sans-serif;
          font-weight: 300;
          color: #FFFFFF;
          padding-top: 6px;
        }
        .mj_honor_list_item_yearNum {
          font-size: 32px;
          font-family: Visby CF, sans-serif;
          font-weight: 500;
          color: #FFE87B;
        }
      }
    }
  }
  .mj_honor_bottom_bar {
    flex-basis: 100%;
    height: 40px;
    background: #181818;
    opacity: 0.98;
    font-size: 14px;
    font-family: Visby CF, sans-serif;
    font-weight: 500;
    color: #FFE87B;
    line-height: 40px;
    text-align: center;
    width: 100%;
    margin-top: 4px;
  }
}

@primary-color: #FFE87B;@animationDelay: 0.6s cubic-bezier(0, 0, 0, 0.8);@maxWidth: 1528px;