.mj_footer_container {
  background: #2D2B27;
  width: 100%;
  margin-top: 4px;
  overflow: hidden;
  transition: 0.4s cubic-bezier(0, 0, 0, 0.8);
  .mj_footer_title_bar {
    height: 24px;
    background: #2D2D2D;
    text-align: center;
    font-size: 14px;

    font-weight: 300;
    color: #C2C2C2;
    line-height: 24px;
    cursor: pointer;
  }
  .mj_footer_content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 8vw;
    white-space: nowrap;
    // .mj_footer_content_left {
    //   padding-top: 32px;
    //   position: relative;
    // }
    .mj_footer_content_right {
      width: 66%;
      display: flex;
      justify-content: space-around;
      padding-top: 32px;
    }
    // .mj_footer_content_left_contactUs_title {
    //   font-size: 14px;

    //   font-weight: 500;
    //   color: #FFFFFF;
    //   margin-bottom: 15px;
    // }
    // .mj_footer_content_left_contact_way {
    //   display: flex;
    //   margin-bottom: 42px;
    //   div {
    //     font-size: 12px;

    //     font-weight: 300;
    //     color: #BDBDBD;
    //     &:first-child{
    //       margin-right: 16px;
    //     }
    //   }
    // }
    // .mj_footer_content_left_contact_link {
    //   display: flex;
    //   justify-content: flex-start;
    //   .mj_footer_content_left_contact_linBtn {
    //     margin-right: 40px;
    //     cursor: pointer;
    //     font-size: 30px !important;
    //     width: 30px;
    //     overflow: hidden;
    //     height: 30px;
    //     color: #FFF;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     position: relative;
    //     bottom: 10px;
    //     overflow: inherit;
    //   }
    // }
    // .mj_footer_content_left_contact_linBtn_wechat {
    //   position: relative;
    //   &:hover {
    //     .mj_footer_content_left_wechat_QRCode {
    //       opacity: 1;
    //       pointer-events: auto;
    //       //display: block;
    //     }
    //   }
    //   .mj_footer_content_left_wechat_QRCode {
    //     position: absolute;
    //     pointer-events: none;
    //     left: 50%;
    //     transform: translateX(-50%);
    //     top: -140px;
    //     transition: 0.3s;
    //     opacity: 0;
    //   }
    //   .mj_footer_content_left_wechat_QRCodeImage {
    //     box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
    //   }
    // }
    // .mj_footer_content_left_border {
    //   width: 1px;
    //   height: 102px;
    //   margin-top: 32px;
    //   background: #BDBDBD;
    // }
    // .mj_footer_content_right_protocol {
    //   display: flex;
    //   // justify-content: space-between;
    //   align-items: center;
    //   min-width: 136px;
    //   height: 17px;
    //   overflow: hidden;
    //   margin-bottom: 13px;
    //   a{
    //     margin-right: 12px;
    //   }
     
    //   //div {
    //   //  font-size: 14px;
    //   //
    //   //  font-weight: 500;
    //   //  color: #FFFFFF;
    //   //  cursor: pointer;
    //   //  &:hover {
    //   //    color: #eeeeee;
    //   //  }
    //   //  &:nth-child(2) {
    //   //    height: 14px;
    //   //    width: 1px;
    //   //    background: #FFFFFF;
    //   //  }
    //   //}
    // }
    .mj_footer_healthGame {
      font-size: 12px;

      font-weight: 300;
      color: #BDBDBD;
      margin-bottom: 28px;
      a {
        color: #BDBDBD;
      }
      div {
        &:first-child {
          margin-bottom: 3px;
        }
      }
    }
  }
}
.protocol_link {
  font-size: 14px;
  font-weight: 500;
  color: #FFFFFF;
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    color: #eeeeee;
  }

  & + .protocol_link {
    position: relative;
    margin-left: 12px;

    &::before {
      position: absolute;
      top: 50%;
      left: -6px;
      transform: translateY(-50%);
      display: inline-block;
      content: '';
      width: 1px;
      height: 14px;
      background-color: #FFF;
    }
  }
}
.mj_show_footer {
  transform: translateY(-170px);
}
.mj_footer_global_container {
  padding-top: 15px;
  .mj_footer_global_protocol {
    display: flex;
    margin: 0 auto 11px;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    font-weight: 300;
    color: #FEFEFE;
    div:nth-child(2) {
      height: 16px;
      width: 1px;
      background: #Fff;
      margin: 0 18px;
    }
    div:nth-child(2n+1) {
      cursor: pointer;
    }
  }
  .mj_footer_global_bottom_text{
    font-family: SimHei, sans-serif;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    color: #9A9A9A;
  }
}

@media screen and (max-width: 1024px){
  .mj_show_footer {
    transform: translateY(0px);
  }
  .mj_footer_container{
    flex-shrink: 0;
  }
}

@primary-color: #FFE87B;@animationDelay: 0.6s cubic-bezier(0, 0, 0, 0.8);@maxWidth: 1528px;