.mj_header_container {
  height: 76px;
  margin: 4px;
  min-width: 1200px;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 8px);
  background: #FFFFFF;
  box-shadow: 0 0 36px 0 rgba(0,0,0,0.05);
  padding-left: 50px;
  z-index: 2;
  .mj_header_Logo {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    height: 50px;
  }
  .mj_header_nav_cursor {
    position: absolute;
    height: 76px;
    left: 0;
    background-color: @primary-color;
    transition: @animationDelay;
  }
  .mj_header_nav_container {
    display: flex;
    float: right;
    justify-content: space-evenly;
    position: relative;
    .mj_header_nav_item {
      position: relative;
      height: 76px;
      line-height: 76px;
      font-size: 15px;
      font-weight: 400;
      color: #B7B3B3;
      cursor: pointer;
      z-index: 1;
      pointer-events: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover{
        .mj_header_select_item {
          display: block;
        }
        background: #EBEBEB;
      }
    }
    .mj_header_select {
      width: 120px;
      height: 76px;
      position: relative;
      .mj_header_select_body {
        position: absolute;
        top: 0;
        opacity: 0;
        transition: 0.25s;
        z-index: 9999;
        pointer-events: none;
        box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
      }
      .colLine {
        width: 1px;
        height: 20px;
        border: 1px solid #D7D7D7;
        position: absolute;
        top: 50%;
        left: -1px;
        transform: translateY(-50%);
      }
      &:hover {
        .mj_header_select_body {
          top: 76px;
          opacity: 1;
          pointer-events: auto;
        }
      }
      .mj_header_select_item {
        display: block;
        text-decoration: none;
        width: 120px;
        height: 76px;
        line-height: 76px;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        color: #B7B3B3;
        background: #FFF;
        cursor: pointer;
        &:hover  {
          background: #ebebeb;
        }
      }
    }

    .mj_header_nav_item_active {
      font-size: 15px;
      font-weight: 600;
      color: #615B41;
      &:hover{
        background: transparent;
      }
    }
    .mj_header_nav_item_contactUsActive {
      font-size: 15px;
      font-weight: 600;
      color: #615B41;
      background: @primary-color;
      transition: @animationDelay;
      &:hover{
        background: @primary-color;
      }
    }
  }
}

@primary-color: #FFE87B;@animationDelay: 0.6s cubic-bezier(0, 0, 0, 0.8);@maxWidth: 1528px;