.mj_news_container {
  overflow: hidden;


  .swiper_container{
    position: relative;
    width: 100%;
    height: calc(var(--vh) - 112px);
    margin: auto;
 
  }
  /* 重要：不设置宽高会出错 */
  .swiper_container .swiper-wrapper{
    width: 100%;
  }
  .mj_show_footer {
    transform: translateY(-170px);
  }
  .mj_news_list {
    margin-bottom: 4px;
    transition: 0.4s cubic-bezier(0, 0, 0, 0.8);
  }
  .mj_news_item {
    background: #FFF;
    width: 100%;
    height: 100%;
    min-height: 858px;
    padding: 4px;
    .mj_news_pic {
      position: relative;
      height: 57%;
      background: #ECECEC;
      overflow: hidden;
    }
    .mj_news_detail{
      position: relative;
    }
    .mj_news_time {
      width: 190px;
      height: 46px;
      background: #2D2D2D;
      transform: translateY(-50%);
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        font-size: 32px;
        font-family: Visby CF, sans-serif;
        font-weight: 500;
        color: #FFFFFF;
      }
      & div:first-child {
        align-self: flex-end;
        margin-bottom: 8px;
        font-size: 14px;
        font-family: Visby CF, sans-serif;
        font-weight: 300;
        color: #FFFFFF;
      }
    }
    .mj_news_title {
      font-size: 24px;
      height: 66px;

      font-weight: 500;
      color: #22211E;
      margin-top: 22px;
      margin-bottom: 45px;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 这里是超出几行省略 */
      overflow: hidden;
      margin-left: 25px;
    }
    .mj_news_desc {
      overflow: hidden;
      font-size: 14px;
      padding-top: 35px;

      font-weight: 300;
      height: 98px;
      color: #9D9D9D;
      line-height: 22px;
      border-top: 1px solid #F2F2F2;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      margin: 0 25px;
    }
    .mj_news_linkBtn {
      position: absolute;
      height: 46px;
      width: calc(100% - 8px);
      background: #EEEEEE;
      bottom: 0;
      left: 4px;
      padding: 0 60px 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      color: #9D9D9D;
      div:first-child {
        font-size: 12px;
      }
      div:nth-child(2) {
        font-size: 16px;
      }
    }
    .mj_news_linkBtn_rightBtn {
      width: 38px;
      height: 38px;
      color: #000;
      background: #FFF;
      position: absolute;
      right: 4px;
      .mj_news_linkBtn_rightBtn_icon {
        font-size: 38px;
      }
    }
  }
}

@media screen and (max-width: 1024px){
  .mj_news_container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .mj_news_list{
    height: 100%;
    flex: 1;

  }
  .mj_news_container{
    .swiper_container{
      height: 100%;
    }
  }


}
@primary-color: #FFE87B;@animationDelay: 0.6s cubic-bezier(0, 0, 0, 0.8);@maxWidth: 1528px;