.mj_products_isNotActive {
  transform: translateY(-50px);
  height: calc(var(--vh) - 34px);
}
.mj_products_Game_Logo {
  position: absolute;
  left: 56px;
  top: 50px;
  z-index: 1;
}
.mj_products_Game_Logo2 {
  position: absolute;
  left: 56px;
  top: 4px;
  z-index: 1;
}
.mj_products_container {
  position: relative;
  height: calc(var(--vh) - 84px);
  overflow: hidden;
  padding-right: 0;
  transition: @animationDelay;
//   :global{
// .swiper-slide{
//   img{
//     height: 100% !important;
//   }

// }
//   }
  .mj_homePage_scrollTips {
    width: 100%;
    //line-height: 50px;
    height: calc(var(--vh) - 34px);
    text-align: center;
    color: #eaeaea;
    cursor: pointer;
    background: rgba(51,51,51,.8);
    opacity: 1;
    z-index: 1;
    position: absolute;
    top: 0;
    transition: @animationDelay;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
  }
  .mj_homePage_scrollTips_hidden {
    cursor: auto;
    pointer-events: none;
    opacity: 0;
    transition: @animationDelay !important;
  }

  .swiper_container{
    position: relative;
    width: 100%;
    height: calc(var(--vh) - 96px);
    margin: auto;
    user-select: none;
  }
  /* 重要：不设置宽高会出错 */
  .swiper_container .swiper-wrapper{
    width: 100%;
    height: 400px;
  }
  .mj_aboutUs_loop_picSelf {
    object-fit: cover;
     height: calc(var(--vh) - 96px);
   
  }
  .mj_products_loop_pic {
    width: 100%;
    height: calc(var(--vh) - 96px);
    background: #232323;
    transition: 0.68s;
  }
  .mj_products_loop_pic_showDetail {
    transform: translateX(-260px);
    transition: 0.6s;
  }
  .mj_products_loop_picSelf {
    object-fit: cover;
    object-position: 50% 50%;
  }
  .mj_products_swiper_process {
    width: calc(57% - 4px);
    height: 4px;
    position: absolute;
    z-index: 2;
    bottom: 4px;
    background: #CFCFCF;
    .mj_products_swiper_process_bar {
      height: 4px;
      width: 100%;
      background: @primary-color;
      
    }
    .test_proce{
      height: 4px;
      width: 100%;
      background: #FFE87B;
   
      box-shadow: 0 6px 16px 0 rgba(0,0,0,.08),0 3px 6px -4px rgba(0,0,0,.12),0 9px 28px 8px rgba(0,0,0,.05);
     animation: process 6000ms linear none ;
    //  animation-delay: 400;
  }
  @keyframes process {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
    .mj_products_swiper_process_bar2 {
      height: 4px;
      width: 100%;
      background: #CFCFCF;
      box-shadow: 0 6px 16px 0 rgba(0,0,0,.08),0 3px 6px -4px rgba(0,0,0,.12),0 9px 28px 8px rgba(0,0,0,.05);
    }
    .mj_products_swiper_process_bar_animation_paused{
      animation-play-state: paused;
    }
    @keyframes process {
      from {
        width: 0;

        background: @primary-color;
      }
      to {
        width: 100%;
        background: @primary-color;
      }
    }
  }
  @media screen and (max-width: 1477px) {
    .mj_products_swiper_process {
      width: calc(57% - 4px) !important;
    }
  }
  .mj_products_video {
    width: 43%;
    // min-width: 626px;
    height: 98px;
    position: absolute;
    right: -820px;
    bottom: 4px;
    display: grid;
    grid-template-columns: 98px 1fr;
    z-index: 100;
    grid-gap: 4px;
    justify-content: space-between;
    //cursor: pointer;
    transition: @animationDelay;

    .mj_products_video_playBtn {
      width: 98px;
      height: 98px;
      background: #232323;
      display: flex;
      justify-content: center;
      align-items: center;
      .mj_products_triangle {
        width: 40px;
        height: 40px;
        border: 2px solid grey;
        border-radius: 50%;
        position: relative;
        &:after {
          content: '';
          display: block;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: grey;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        //width: 0;
        //height: 0;
        //transform: translateX(32%);
        //border: 16px solid transparent;
        //border-left: 25px solid yellow;
      }
    }
    .mj_products_video_playDesc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 98px;
      padding: 0 40px;
      font-size: 10px;
      font-family: Mark Pro, sans-serif;
      font-weight: 400;
      color: #BDBDBD;
      position: relative;
      div{
        z-index: 2;
      }
      .mj_products_video_playDesc_img {
        object-fit: cover;
        position: absolute;
        left: 600px;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: rgba(35,35,35,.65);
        z-index: 1;
      }
    }
  }
  .mj_products_video_show {
    right: 0;
  }
  .mj_homePage_scrollTips_svg {
    position: absolute;
    animation: upAndDown 1.2s infinite linear;
  }
  @keyframes upAndDown {
    0% {
      top: 3px;
    }
    50% {
      top: 8px;
    }
    100% {
      top: 3px;
    }
  }
  .mj_products_detail_toggle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 54px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    transition: cubic-bezier(0, 0, 0, 0.8) 0.56s !important;
  }
}
.mj_products_flex{
  display: grid;
  grid-template-columns: 100% 1fr;
  width: 100%;
  overflow: hidden;

  .mj_test:nth-child(1) .mj_products_flex_card{
    background:url('../../public/images/products/p1/slogn.png') ;
    background-size: cover;
  }
  .mj_test:nth-child(2) .mj_products_flex_card{
    background:url('../../public/images/products/p3/slogn.jpg') ;
    background-size: cover;
  }
}
.mj_products_flex_card{
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  z-index: 100;
  overflow: hidden;
 
  background-size: cover;
  &::before{
    content: '';
    background-color: #141414;
    opacity: 0.6;
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .title{
    z-index: 1;
    opacity:0.6;
    font-family:"Microsoft YaHei", "Microsoft YaHei UI","微软雅黑", sans-serif;
  
  }



}

.mj_products_mask{
  &::before{
    opacity:0.2;
  }
  .title{
    opacity:1;
  }

 
}

.mj_products_card_btn{
  position: absolute;
  bottom: 10px;
  right: 0;
  z-index: 112;
  height: 50px;
  display: grid;
  grid-template-columns: 50% 1fr;
  grid-gap: 4px;
  span{
    width: 258px;
    background: #FFE25A;
    color: #615B41;
    font-size: 14px;
    line-height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
 .mj_products_flex_card{
  width: 200%;

}
.mj_test{
  overflow: hidden;
  height: 100%;
  display: flex;
}
// .mj_products_p1{
//   .mj_test{
//     width: 100%;
//   }

//   .mj_test:nth-child(2) .mj_products_flex_card{
 
//     animation: moveR 1s linear forwards;
//   }

// }
// .mj_products_p3{
//   .mj_test{
//     width: calc(200% + 16px);
//   }
//   .mj_test:nth-child(2){
//     transform: translateX(-50%);
//   }
//   .mj_test:nth-child(1) .mj_products_flex_card{
 
//     animation: moveL 1s linear forwards;
//   }
//   .mj_test:nth-child(2) .mj_products_flex_card{

 
//     animation: moveL2 1s linear forwards;
//   }

// }
@keyframes moveR{
  0%{
  transform: translateX(0);
  }
  100%{
    transform: translateX(100%);

  }
}
@keyframes moveL{
  0%{
  transform: translateX(0);
  }
  100%{
    transform: translateX(-100%);

  }
}
@keyframes moveL2{
  0%{
  transform: translateX(0);
  }
  100%{
    transform: translateX(0%);

  }
}


// .swiperH100Wrapper{
//  width: 100%;
//   display: flex;
// }
.swiperW100{
  width: 100%;
  img{
    object-fit: cover !important;
  }
  
 }
.right_arrow,.left_arrow{
  font-size: 40px;
}
.left_arrow{
  transform: rotate(180deg);
}
.mj_products_p1{
  .mj_test_next{
    animation:upto  0.6s linear forwards ;
  }
}

// .mj_test_cureent{
//   animation:downto  0.6s linear forwards ;
// }
.mj_products_p3{
  .mj_test_next{
    animation:downto  0.6s linear forwards ;
  }
}
.left_to_right{


  .mj_test{
    width:calc(200% + 18px);
   
  }
  .mj_test0{
    transform: translateX(0%);

  }
  .mj_test1{
    transform: translateX(-50%);

  }

  .mj_test_next .mj_products_flex_card{
    animation:up  .6s linear forwards ;
    
  }
  
  .mj_test_cureent .mj_products_flex_card{
    animation: down 0.6s linear forwards ;
    
  }




}
@keyframes upto{
  0%{
    transform: translateX(-50%);


  }
  100%{
    transform: translateX(0%);
   
   
  }

}
@keyframes downto{
  0%{
    transform: translateX(0%);


  }
  100%{
    transform: translateX(-50%);
  
    
   
   
  }

}
@keyframes up{
  0%{
    opacity: 1;
    transform: translateX(100%);


  }
  100%{
    transform: translateX(0%);
    z-index: 1;
   
  }

}
@keyframes down{
  0%{
    transform: translateX(0%);


  }
  100%{
    transform: translateX(-100%);
   
    
  }

}
// .swiperW100H{
//   display: flex;
//   width: 100%;
// }
.banner_two_hidden{
  width:200%;

  visibility: hidden;
  img{
    object-fit: cover !important;
  }
}
.mj_products_p1{
  .banner_two_show{
    animation:upto2  0.6s linear forwards ;
  
  }
}

@keyframes upto2{
  0%{
    transform: translateX(-50%);


  }
  100%{
    transform: translateX(0%);
   
    visibility: visible;
   
  }

}
@keyframes upto21{
  0%{
    transform: translateX(50%);


  }
  100%{
    transform: translateX(0%);
   
    visibility: visible;
   
  }

}
.banner_slogn{
  display: grid;
  grid-template-columns: 50% 1fr;
  width: 200%;
  grid-column-gap: 4px;
  visibility: visible;
  animation:upto4 0.6s linear forwards ;
  
}
.mj_products_p1{
  .banner_hiden{
    // animation:upto3 0.6s linear forwards ;
    animation: none;
    width: 200%;
    transform: translateX(-100%);
    .mj_test_cureent{
      opacity: 0;
    }
    .mj_test_next{
    
       animation:upto3 0.6s linear forwards ;
    }
  }
}
.mj_products_p3{
  .banner_two_show{
    animation:upto21  0.6s linear forwards ;
  
  }
  .banner_hiden{
    // animation:upto3 0.6s linear forwards ;
    animation: none;
    width: 200%;
    transform: translateX(-100%);
    .mj_test_next{
      opacity: 0;
      animation: 0;
    }
    .mj_test_cureent{
    
       animation:upto31 0.6s linear forwards ;
    }
  }
}


@keyframes upto3{
  0%{
    transform: translateX(0%);
  }
  100%{
    transform: translateX(100%);
    visibility: visible;
   
   
  }

}
@keyframes upto31{
  0%{
    transform: translateX(0%);
  }
  100%{
    transform: translateX(-100%);
    visibility: visible;
   
   
  }

}

@keyframes upto4{
  0%{
    transform: translateX(-25%);


  }
  100%{
    transform: translateX(-100%);
  
   
   
  }

}



.all_banner{
  // width: 200%;
  display: flex;
  img{
    object-fit: cover;
   

   
   
  }
}

.all_banner_img2{
  transform: scale(1.15) translateX(4px);
}
.banner_all{
  width: 100%;

}
.banner_two{
 
  // display: grid;
  // grid-template-columns: 50% 1fr;
 
  // grid-column-gap: 4px;

  
}

@primary-color: #FFE87B;@animationDelay: 0.6s cubic-bezier(0, 0, 0, 0.8);@maxWidth: 1528px;