.protocol_container{
  overflow: auto;
  &::after {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    background-color: rgba(60,60,60,.6);
    z-index: 2;
  }
  &::before {
    content: "";
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100%;
    background: url("../../public/images/homepage-poster.png") no-repeat;
    background-size: cover;
    z-index: 2;
  }
  .protocol_content {
    position: relative;
    padding: 0 38px;
    max-width: 1280px;
    margin: 20px auto;
    background: rgba(255,255,255);
    border-radius: 2px;
    z-index: 3;
  }
  .protocol_title {
    height: 110px;
    border-bottom: 1px solid rgba(63,63,63,.2);
    font-size: 28px;
    font-family: Source Han Sans CN, sans-serif;
    font-weight: bold;
    color: #3F3F3F;
    line-height: 110px;
    text-align: center;
  }
  .mj_privacy_content {
    max-width: 1280px;
    background: rgba(255,255,255);
    border-radius: 2px;
    margin: 42px 0;
  }
  .protocol_footer {
    height: 110px;
    border-top: 1px solid rgba(63,63,63,.2);
    color: #3F3F3F;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a{
    text-decoration: underline ;
    color: #007aff !important;
    span{
      color: #007aff !important;
    }
   
  }
}

@primary-color: #FFE87B;@animationDelay: 0.6s cubic-bezier(0, 0, 0, 0.8);@maxWidth: 1528px;