.mj_aboutUs_container {
  position: relative;
  overflow: hidden;
  height: calc(var(--vh) - 84px);
  user-select: text;
  .swiperWrapper{
    width: 100%;
    transition: 0.4s cubic-bezier(0, 0, 0, 0.8);
  }
  .swiperHide {
    height: 0 !important;
    overflow: hidden !important;
  }
  .mj_aboutUs_loop_pic {
    width: 100%;
    height: 100%;
    transition: 0.4s cubic-bezier(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: rgba(60,60,60,.6);
      z-index: 2;
    }
  }
  .mj_aboutUs_loop_picSelf {
    object-fit: cover;
  }

  .mj_aboutUs_content_show {
    left: 15% !important;
  }

  .mj_aboutUs_content{
    position: absolute;
    top: 48%;
    transform: translateY(-48%);
    left: 17%;
    z-index: 3;
    transition: 0.4s cubic-bezier(0, 0, 0, 0.8);
    .mj_aboutUs_content_title {
      margin-bottom: 83px;
      .mj_aboutUs_content_mainTitle {
        font-size: 72px;

        font-weight: 800;
        color: #FFFFFF;
        height: 73px;
        line-height: 73px;
        margin-bottom: 5px;
      }
      .mj_aboutUs_content_subTitle {
        font-size: 56px;
        font-family: Fontspring;
        color: #FFE66F;
        font-weight: 400;
        height: 52px;
        line-height: 52px;
      }
    }
    .mj_aboutUs_content_detail {
      white-space: pre-line;
      width: 789px;
      font-size: 16px;

      font-weight: 400;
      color: #FFF;
      line-height: 24px;
      word-break: break-word;
      margin-bottom: 109px;
    }
  }
  .aboutUs_link {
    width: 400px;
    min-width: 400px;
    height: 46px;
    background: @primary-color;
    font-size: 17px;

    font-weight: bold;
    color: #22211E;
    text-align: center;
    position: relative;

    padding-left: 54px;
    transition: @animationDelay;
    cursor: pointer;
    opacity: 1;
    overflow: hidden;
    .aboutUs_link_content {
      height: 46px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .aboutUs_link_dot {
      width: 4px;
      height: 4px;
      background: #22211E;
    }
    .aboutUs_link_dot_left {
      margin-right: 12px;
    }
    .aboutUs_link_dot_right {
      margin-left: 12px;
      margin-right: 52px;
    }
    .aboutUs_link_subtitle {
      font-size: 12px;
      font-family: Visby CF;
      font-weight: 300;
      color: #615B41;
      position: absolute;
      right: 121px;
    }
    .aboutUs_link_right_arrow {
      position: absolute;
      right: 4px;
      font-size: 40px;
    }
  }
  .aboutUs_link_hidden {
    height: 0;
    pointer-events: none;
  }
  .aboutUs_link_active {
    width: 648px;
  }

  .mj_joinUs_container {
    height: 208px;
    overflow: hidden;
    background: #22211E;
    margin-top: 4px;
    padding: 4px;
    transition: 0.4s cubic-bezier(0, 0, 0, 0.8);
    opacity: 1;
    position: relative;
  }
  .mj_joinUs_container_show{
    height: calc(var(--vh) - 160px);
  }
  .mj_joinUs_container_hide {
    height: 0;
    opacity: 0;
    overflow: hidden;
  }
  .mj_joinUs_position_body {
    display: flex;
    flex: 0 0 auto;
    margin-bottom: 4px;
    .mj_joinUs_position_item {
      height: 80px;
      width: 100%;
      background: #2D2B27;
      margin-right: 4px;
      cursor: pointer;
      padding: 0 38px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: 0.25s;
      &:hover {
        background: #3B3934;
      }
      &:last-child {
        margin-right: 0;
      }
      .mj_joinUs_position_name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .mj_joinUs_position_name_dot {
        width: 4px;
        height: 4px;
        background: #FFFFFF;
        margin-right: 8px;
      }
      .mj_joinUs_position_name_text {
        font-size: 20px;

        font-weight: 300;
        color: #FEFEFE;
      }
      .mj_joinUs_position_subName {
        font-size: 17px;
        font-family: Visby CF, sans-serif;
        font-weight: 500;
        color: #45423D;
        display: flex;
        justify-content: center;
        align-items: center;
        .mj_joinUs_position_right_arrow {
          font-size: 30px;
          color: @primary-color;
          transition: 0.25s linear;
        }

        .mj_joinUs_position_right_arrow_active {
          transform: rotateZ(90deg);
        }
      }
    }
    .mj_joinUs_position_item_active {
      background: #3B3934;
      .mj_joinUs_position_name_dot {
        background: @primary-color;
      }
      .mj_joinUs_position_name_text {
        color: @primary-color;
      }
    }
  }
  .mj_joinUs_global_position_body {
    width: 100%;
    opacity: 0;
    transition: 0.4s cubic-bezier(0, 0, 0, 0.8);
    position: relative;
    overflow: hidden;
    .mj_joinUs_global_position {
      max-width: 1280px;
      margin: 40px auto 0;
      .mj_joinUs_global_position_title {
        display: flex;
        height: auto;
        justify-content: space-between;
        margin: 40px 0;
        .mj_joinUs_global_position_title_item {
          text-align: center;
          height: 40px;
          line-height: 40px;
          background: #3B3934;
          flex: 1;
          font-size: 20px;

          font-weight: 300;
          color: #FEFEFE;
          margin-right: 4px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .mj_joinUs_global_position_list {
        height: calc(var(--vh) - 570px);
        overflow-y: auto;
        .mj_joinUs_global_position_list_item {
          width: 100%;
          display: flex;
          text-decoration: none;
          justify-content: space-between;
          align-self: center;
          font-size: 20px;

          font-weight: 300;
          color: #FEFEFE;
          cursor: pointer;
          transition: 0.15s;
          &:last-child {
            border-bottom: 1px solid #3B3934;
          }
          &:hover {
            background: #3B3934;
          }
          div {
            border-top: 1px solid #3B3934;
            flex: 1;
            height: 58px;
            line-height: 58px;
            text-align: center;
          }
        }
      }
    }
    .mj_joinUs_global_position_closeBtn{
      width: 268px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #2D2B27;
      font-size: 20px;
      font-family: Visby CF, sans-serif;
      font-weight: 300;
      color: #FEFEFE;
      position: absolute;
      bottom: 40px;
      right: 0;
      left: 0;
      margin: 0 auto;
      cursor: pointer;
      transition: 0.4s cubic-bezier(0, 0, 0, 0.8);
      &:hover {
        background: #3B3934;
      }
    }
  }
  .mj_joinUs_global_position_body_show {
    opacity: 1;
    height: calc(var(--vh) - 336px);
    overflow: auto;
  }
  .mj_joinUs_moka {
    height: 80px;
    line-height: 80px;
    width: 100%;
    background: #2D2B27;
    text-align: center;
    font-size: 16px;

    font-weight: 300;
    color: #FEFEFE;
    cursor: pointer;
    &:hover {
      background: #3B3934;
    }
  }
  .mj_joinUs_bottom_text {
    text-align: center;
    width: 100%;
    font-size: 12px;
    scale: 0.8;
    font-family: Mark Pro, sans-serif;
    font-weight: 500;
    color: #483D3C;
    position: absolute;
    bottom: 12px;
  }
  .mj_joinUs_international_title {
    font-size: 26px;
    height: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #858585;
  }
  .mj_joinUs_international_dot {
    width: 4px;
    height: 4px;
    background: #858585;
    margin: 0 15px;
  }
}
@media screen and (max-width: 1632px){
  .aboutUs_link_active {
    width: calc(83vw - 710px) !important;
  }
  .aboutUs_link_subtitle {
    right: 50px !important;
  }
}

@primary-color: #FFE87B;@animationDelay: 0.6s cubic-bezier(0, 0, 0, 0.8);@maxWidth: 1528px;