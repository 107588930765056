.productsDetails_container {
  width: 100%;
  background: #FFFFFF;
  height: calc(var(--vh) - 183px);
  padding: 11vh 4px 0 150px;
  position: relative;
  transition: @animationDelay;
  overflow-y: auto;
  .mj_container_bgc {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    min-height: 450px;
  }
  .productsDetails_title_body {
    display: flex;
    align-items: baseline;
    margin-bottom: 11.1vh;
    position: relative;
    z-index: 1;
    height: 34px;
    line-height: 34px;
    .productsDetails_title_name {
      font-size: 34px;
      font-weight: bold;
      color: #3C3232;
      margin-right: 12px;
    }
    .productsDetails_title_subTitle {
      font-size: 20px;
      font-family: Alfphabet, sans-serif;
      font-weight: normal;
      color: #3C3232;
      //padding-top: 15px;
    }
    .productsDetails_title_subTitle_krOjp {
      padding-top: 0;
    }
  }
  .productsDetails_title_body_en{
    margin-bottom: 8.1vh;
  }
  .productsDetails_title_body_krOjp {
    display: block;
    margin-bottom: 8.2vh;
  }
  .productsDetails_introduce {
    position: relative;
    z-index: 1;
    margin-bottom: 4.1vh;
    .productsDetails_introduce_title {
      font-size: 16px;

      font-weight: 500;
      color: #3F3F3F;
      margin-bottom: 2px;
    }
    .productsDetails_introduce_subtitle {
      font-size: 14px;
      font-family: Mark Pro, sans-serif;
      font-weight: 400;
      color: #C4C4C4;
    }
  }
  .productsDetails_detail {
    white-space: pre-line;
    position: relative;
    z-index: 1;
    width: 380px;
    font-size: 16px;
    max-height: 258px;
    overflow-y: auto;
    font-weight: 400;
    color: #404040;
    line-height: 30px;
    flex:1;
    min-height: 100px;
    // margin-bottom: 145px;
  }
  .productsDetails_flex{
    display: flex;
    flex-direction: column;
  }
  .productsDetails_link_body {

    position: relative;
    z-index: 1;
    display: flex;
    .productsDetails_link_btn{
      height: 40px;
      margin-right: 4px;
      cursor: pointer;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      svg {
        fill: #FFF
      }
    }
    .productsDetails_link_website {
      width: 186px;
      line-height: 40px;
      background: #3F3F3F;
      font-size: 17px;

      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .productsDetails_link_website_dot {
        width: 4px;
        height: 4px;
        background: #FFF;
      }
      .productsDetails_link_website_dot_left {
        margin-right: 12px;
      }
      .productsDetails_link_website_dot_right {
        margin-left: 12px;
      }
    }
    .productsDetails_link_bilibili{
      font-size: 40px;
      background: #1DB4E6;
      //background: #BDBDBD;
    }
    .productsDetails_link_weibo{
      font-size: 40px;
      svg {
        fill: #DF152C;
      }
    }
    .productsDetails_link_wechat{
      font-size: 40px;
      background: #07C160;
      position: relative;
      overflow: inherit;
      &:hover {
        .productsDetails_link_wechat_QRCode {
          opacity: 1;
          pointer-events: auto;
          //display: block;
        }
      }
      .productsDetails_link_wechat_QRCodeImage {
        box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
      }
      .productsDetails_link_wechat_QRCode {
        pointer-events: none;
        position: absolute;
        top: -140px;
        //display: none;
        transition: 0.3s;
        opacity: 0;
      }
    }
    .productsDetails_link_facebook{
      svg {
        fill:  #4867AA;
      }
      font-size: 40px !important;
      overflow: hidden;
    }
    .productsDetails_link_twitter {
      width: 40px;
      background: #1E1E1E;
    }
    .productsDetails_link_youtube {
      font-size: 40px !important;
      background: #DF152C;
      overflow: hidden;
    }
    .productsDetails_link_cafe {
      font-size: 40px;
      overflow: hidden;
      svg {
        fill: #17CC34;
      }
    }
  }
  .productsDetails_switchProducts_button_body{
    display: none;
    height: 50px;
    width: 100%;
    position: absolute;
    bottom: 4px;
    left: 4px;
    //display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;
    .productsDetails_switchProducts_button {
      background: @primary-color;
      cursor: pointer;
    }
  }
}

.productsDetails_container_show {
  padding-left: 70px;
  display: flex;
  padding-bottom: 147px;
}

@primary-color: #FFE87B;@animationDelay: 0.6s cubic-bezier(0, 0, 0, 0.8);@maxWidth: 1528px;