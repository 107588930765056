.mj_ContactUs_container {
  background: #232323;
  position: relative;
  .mj_ContactUs_title {
    height: 111px;
    padding-left: 42px;
    display: flex;
    line-height: 111px;
    border-bottom: 2px solid #2E2E2E;
    .mj_ContactUs_title_compName {
      font-size: 32px;
      font-weight: bold;
      color: #FFFFFF;
      margin-right: 7px;
    }
    .mj_ContactUs_title_subTitle {
      font-size: 17px;
      font-weight: normal;
      color: @primary-color;
      font-family: Alfphabet, sans-serif;
      padding-top: 8px;
    }
  }

  .mj_ContactUs_content {
    padding: 40px 42px;
    .mj_ContactUs_content_title {
      display: flex;
      margin-bottom: 30px;
    }
    .mj_ContactUs_content_titleName {
      font-size: 18px;

      font-weight: bold;
      color: #FFFFFF;
      margin-right: 8px;
    }
    .mj_ContactUs_content_subTitleName {
      font-size: 12px;
      font-family: Mark Pro, sans-serif;
      font-weight: 400;
      color: #4B4B4B;
      padding-top: 9px;
    }
  }

  .mj_ContactUs_contactWay {
    margin-bottom: 66px;
    .mj_ContactUs_contactWay_content {
      font-size: 16px;

      font-weight: 300;
      color: #FFFFFF;
      line-height: 26px;
    }
  }
  .mj_ContactUs_contactWay_social {
    display: flex;
    justify-content: flex-start;
  }
  .mj_ContactUs_contactWay_socialItem{
    font-size: 30px;
    margin-right: 39px;
    color: #eaeaea;
    cursor: pointer;
  }
  .mj_ContactUs_contactWay_socialItem_weChat {
    position: relative;
    &:hover {
      .mj_ContactUs_contactWay_socialItem_weChat_QRCode {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  .mj_ContactUs_contactWay_socialItem_weChat_QRCode {
    position: absolute;
    pointer-events: none;
    left: 50%;
    transform: translateX(-50%);
    top: -140px;
    transition: 0.3s;
    opacity: 0;
  }
  .mj_ContactUs_contactWay_wechat_QRCodeImage {
    box-shadow: -6px 0 16px 0 rgba(0, 0, 0, 0.08), -3px 0 6px -4px rgba(0, 0, 0, 0.12), -9px 0 28px 8px rgba(0, 0, 0, 0.05);
  }
  .mj_ContactUs_bottom_btn {
    position: absolute;
    bottom: -50px;
    width: 528px;
    left: 0;
    height: 46px;
    line-height: 46px;
    background: @primary-color;
    font-size: 12px;
    font-family: Visby CF, sans-serif;
    font-weight: 600;
    color: #232323;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mj_drawer_closeBtn {
    background: transparent;
    width: 12px;
    height: 12px;
    margin-right: 10px;
  }
}

@primary-color: #FFE87B;@animationDelay: 0.6s cubic-bezier(0, 0, 0, 0.8);@maxWidth: 1528px;